import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initQRScanner } from '@telegram-apps/sdk-react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { ActionPopup, Button, Input, OutputRequest, Transaction } from 'components';
import { TransStatusEnum } from 'common/interfaces';
import { setBackBtn } from 'common/utils';
import {
	getOutputRequest,
	getTransactions,
	outputRequest,
} from 'store/reducers/userSlice';

import usdtImg from 'assets/img/usdt.svg';
import trxImg from 'assets/img/trx.svg';
import styles from './Send.module.scss';
import { getSettings } from 'store/reducers/settingsSlice';
import { ScanIcon, SuccessIcon } from 'icons';
import { setNotice, toggleInDev } from 'store/reducers/appSlice';

interface ISend {}

const Send: FC<ISend> = ({}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	setBackBtn(() => {
		navigate('/');
	});

	const [sent, setSent] = useState(false);
	const [address, setAddress] = useState('');
	const [sum, setSum] = useState('');

	const { user, wallet, transactions, outputReq } = useAppSelector((state) => state.user);
	const { settings } = useAppSelector((state) => state.settings);

	useEffect(() => {
		dispatch(getTransactions());
		dispatch(getOutputRequest());
		dispatch(getSettings());
	}, [dispatch]);

	const onPopupClose = () => {
		setSent(false);
		setSum('');
		setAddress('');
	};

	const fee = settings.find((el) => el.name === 'output-fee')?.value || 2;

	const isButtonDisabled =
		transactions.findIndex((el) => el.status === TransStatusEnum.WAIT) !== -1 ||
		!!outputReq ||
		sum === '' ||
		Number.isNaN(+sum) ||
		+sum < fee + 1 ||
		+sum > wallet.availableSum;

	return (
		<div className={styles.wrapper}>
			{outputReq ? (
				<div className={styles.content}>
					<OutputRequest />
				</div>
			) : (
				<>
					<div className={styles.content}>
						<div>
							<div onClick={() => dispatch(toggleInDev())} className={styles.block}>
								<img src={trxImg} alt="usdt" width={38} height={38} />
								<div className={styles.block_info}>
									<p>TRC 20</p>
									<p className={styles.blue}>
										Изменить сеть{' '}
										<svg
											width="6"
											height="9"
											viewBox="0 0 6 9"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M1.45508 8.46004L4.71508 5.20004C5.10008 4.81504 5.10008 4.18504 4.71508 3.80004L1.45508 0.540039"
												stroke="var(--link-color)"
												stroke-miterlimit="10"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</p>
								</div>
								<div className={styles.block_value}>
									<p>{fee} USDT</p>
									<p>Payment fee</p>
								</div>
							</div>
							<div className={styles.block} style={{ margin: '20px 0 12px' }}>
								<img src={usdtImg} alt="usdt" width={38} height={38} />
								<div className={styles.block_info}>
									<p>Tether</p>
								</div>
								<div className={styles.block_value}>
									<p>{wallet.availableSum} USDT</p>
									<p>$ {wallet.availableSum}</p>
								</div>
							</div>
						</div>
						<div className={styles.qr_wrapper}>
							<Input
								placeholder="Address"
								value={address}
								className={styles.input}
								onChange={(e) => setAddress(e.currentTarget.value)}
							/>
							<button
								onClick={() => {
									const scanner = initQRScanner();
									scanner.open().then((qr) => {
										qr
											? setAddress(qr)
											: dispatch(setNotice({ message: '', type: 'error' }));
									});
								}}
								className={styles.qr_button}>
								<ScanIcon />
							</button>
						</div>
						<Input
							type="number"
							pattern="[0-9]*"
							inputMode="decimal"
							min={fee + 1}
							max={wallet.availableSum}
							placeholder={`Min ${
								fee + 1 < wallet.availableSum ? fee + 1 : wallet.availableSum
							} - Max ${wallet.availableSum.toFixed(0)}`}
							value={sum}
							className={styles.input}
							onChange={(e) => setSum(e.currentTarget.value)}
						/>
						<div className={styles.balance}>
							<p>Your Balance</p>
							<p>{wallet.availableSum.toFixed(2)} USDT</p>
						</div>
						<div className={styles.send_block}>
							<Button
								className={styles.button}
								size="small"
								disabled={isButtonDisabled}
								onClick={async () => {
									if (!address || !sum) return;
									if (Number.isNaN(+sum)) return;
									if (+sum > wallet.availableSum) return;

									const actionResult = await dispatch(
										outputRequest({
											address: address,
											sum: +sum,
											userId: user.id,
										})
									);

									if (outputRequest.fulfilled.match(actionResult)) {
										setSent(true);
									}
								}}>
								Send
							</Button>
						</div>
					</div>
				</>
			)}
			{transactions.length > 0 && (
				<div className={styles.content}>
					<div className={styles.name}>RECENT</div>
					<div className={styles.list}>
						{transactions.map((el) => (
							<Transaction data={el} />
						))}
					</div>
				</div>
			)}
			<ActionPopup show={sent} onClose={onPopupClose}>
				<div className={styles.popup}>
					<div className={styles.popup_image}>
						<SuccessIcon />
					</div>
					<div className={styles.popup_title}>A withdrawal request has been formed</div>
					<div className={styles.popup_text}>
						<g>{sum} USDT</g> was sent to the account of{' '}
						<b>
							{address.substring(0, 4) + '***' + address.substring(address.length - 4)}
						</b>{' '}
						Expect a transfer within 24 hours
					</div>
					<Button size="small" onClick={onPopupClose} className={styles.popup_btn}>
						Okey
					</Button>
				</div>
			</ActionPopup>
		</div>
	);
};

export default Send;
