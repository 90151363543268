import { SVGProps } from 'react';

const ScanIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<rect
			width={14.5}
			height={14.5}
			x={0.75}
			y={0.75}
			stroke="#007AFF"
			strokeDasharray="10 3"
			strokeWidth={1.5}
			rx={1.25}
		/>
		<path stroke="#007AFF" strokeWidth={1.5} d="M4 8.25h8" />
	</svg>
);
export default ScanIcon;
