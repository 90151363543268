import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import {
	clearHoldings,
	getHoldings,
	getHoldingsHistory,
} from 'store/reducers/holdingSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setBackBtn } from 'common/utils';
import { Strategies } from 'components';
import { CircleInfoIcon } from 'icons';

import styles from './Holdings.module.scss';
import { initSwipeBehavior, SwipeBehavior } from '@telegram-apps/sdk-react';
import Chart from 'components/Chart/Chart';

const Holdings: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	setBackBtn(() => navigate('/'));

	const [time, setTime] = useState(Date.now());
	const [activeTab, setActiveTab] = useState<'active' | 'history'>('active');
	const { holdings, history } = useAppSelector((state) => state.holding);

	useEffect(() => {
		dispatch(getHoldings());
		dispatch(getHoldingsHistory());

		const timer = setInterval(() => {
			setTime(Date.now());
		}, 60 * 1000);

		let swipe: SwipeBehavior | null = null;
		try {
			[swipe] = initSwipeBehavior();
			swipe.disableVerticalSwipe();
		} catch {}

		return () => {
			dispatch(clearHoldings());
			clearInterval(timer);

			try {
				swipe?.enableVerticalSwipe();
			} catch {}
		};
	}, [dispatch]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>My Strategies</div>
			<div className={styles.content}>
				{holdings.length > 0 && (
					<div style={{ marginTop: 16 }}>
						<Chart />
					</div>
				)}
				<div className={styles.holdings}>
					<div className={styles.switchers}>
						<button
							onClick={() => setActiveTab('active')}
							className={cn(styles.switch, { [styles.active]: activeTab === 'active' })}>
							Active Strategies
						</button>
						{history.length !== 0 && (
							<button
								onClick={() => setActiveTab('history')}
								className={cn(styles.switch, {
									[styles.active]: activeTab === 'history',
								})}>
								My History
							</button>
						)}
					</div>
					{activeTab === 'active' ? (
						holdings.length === 0 ? (
							<div className={styles.empty}>
								<CircleInfoIcon />
								<p>You don't have any active strategies yet</p>
							</div>
						) : (
							<div className={styles.list}>
								{holdings.map((el) => {
									const timestamp =
										(new Date(el.createdAt).getTime() +
											el.strategy.days * 24 * 60 * 60 * 1000 -
											time) /
										(1000 * 60);
									const days = Math.floor(timestamp / (60 * 24));
									const hours = Math.floor((timestamp / 60) % 24);
									const minutes = Math.floor(timestamp % 60);

									return (
										<div key={el.id} className={styles.item}>
											<div className={styles.name}>
												<div className={styles.image}></div>
												{el.strategy.name}
											</div>
											<div className={styles.rate}>
												<div>+ {(el.sum * (el.strategy.rate - 1)).toFixed(2)}$</div>
												<div className={styles.dot}></div>
												<div>{((el.strategy.rate - 1) * 100).toFixed(2)}%</div>
											</div>
											<div>
												<div className={styles.info_label}>My Deposit:</div>
												<div className={styles.dep}>{el.sum.toFixed(2)} USDT</div>
											</div>
											{el.isFinished ? (
												<>DONE</>
											) : (
												<div>
													<div className={styles.info_label}>Time remaining</div>
													<div className={styles.date}>
														{days}d. {hours}h. {minutes}min.
													</div>
												</div>
											)}
										</div>
									);
								})}
							</div>
						)
					) : (
						<div className={styles.list}>
							{history.map((el) => {
								return (
									<div key={el.id} className={styles.item}>
										<div className={styles.name}>
											<div className={styles.image}></div>
											{el.strategy.name}
										</div>
										<div className={styles.rate}>
											<div>+ {(el.sum * (el.strategy.rate - 1)).toFixed(2)}$</div>
											<div className={styles.dot}></div>
											<div>{((el.strategy.rate - 1) * 100).toFixed(2)}%</div>
										</div>
										<div>
											<div className={styles.info_label}>Placement period:</div>
											<div className={styles.date}>{el.strategy.days}</div>
										</div>
										<div>
											<div className={styles.info_label}>My Deposit:</div>
											<div className={styles.dep}>{el.sum.toFixed(2)} USDT</div>
										</div>
										<div>
											<div className={styles.info_label}>Total:</div>
											<div className={styles.total}>
												{(el.sum * el.strategy.rate).toFixed(2)} USDT
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<Strategies />
			</div>
		</div>
	);
};

export default Holdings;
