import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setBackBtn } from 'common/utils';
import { getTransactions } from 'store/reducers/userSlice';
import { OutputRequest, Transaction } from 'components';

import styles from './Transactions.module.scss';
import { TransActionEnum } from 'common/interfaces';
import { CircleInfoIcon } from 'icons';

const switches = ['All', 'Sends', 'Deposits'] as const;

interface ITransactions {}

const Transactions: FC<ITransactions> = ({}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	setBackBtn(() => navigate('/'));

	const [activeTab, setActiveTab] = useState<(typeof switches)[number]>('All');
	const { transactions } = useAppSelector((state) => state.user);

	useEffect(() => {
		dispatch(
			getTransactions(
				activeTab === 'Sends'
					? TransActionEnum.OUTPUT
					: activeTab === 'Deposits'
					? TransActionEnum.INPUT
					: undefined
			)
		);
	}, [activeTab]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Transactions</div>
			<OutputRequest />
			<div className={styles.switchers}>
				{switches.map((el) => (
					<button
						key={el}
						className={cn(styles.switch, { [styles.active]: el === activeTab })}
						onClick={() => {
							setActiveTab(el);
						}}>
						{el}
					</button>
				))}
			</div>
			{transactions.length === 0 ? (
				<div className={styles.empty}>
					<CircleInfoIcon />
					You don't have any{' '}
					{activeTab === 'All'
						? 'transactions'
						: activeTab === 'Sends'
						? 'sends'
						: 'deposits'}{' '}
					yet
				</div>
			) : (
				<div className={styles.list}>
					{transactions.map((trans) => (
						<Transaction data={trans} key={trans.id} />
					))}
				</div>
			)}
		</div>
	);
};

export default Transactions;
