import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { getHoldingsStats } from 'store/reducers/holdingSlice';
import { ContentType } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import styles from './Chart.module.scss';

const filters = [7, 30, 90, 180] as const;

interface IChart {}

const Chart: FC<IChart> = () => {
	const dispatch = useAppDispatch();

	const { stats } = useAppSelector((state) => state.holding);

	const [filter, setFilter] = useState<(typeof filters)[number]>(7);

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			ref.current.addEventListener(
				'touchstart',
				(e) => {
					e.preventDefault();
				},
				{ passive: false }
			);
			ref.current.addEventListener(
				'touchmove',
				(e) => {
					e.preventDefault();
				},
				{ passive: false }
			);
		}
	}, [ref]);

	useEffect(() => {
		dispatch(getHoldingsStats(filter));
	}, [dispatch, filter]);

	if (!stats) return <></>;

	return (
		<div className={styles.chart}>
			<div>
				<div className={styles.chart_label}>Strategies balance</div>
				<div className={styles.chart_value}>{stats.currentBalance} USDT</div>
				<div className={styles.chart_rate}>
					+{((stats.currentResult / stats.currentBalance - 1) * 100 || 0).toFixed()}%
				</div>
			</div>

			<ResponsiveContainer
				ref={ref}
				style={{ touchAction: 'none' }}
				width="100%"
				height={85}>
				<AreaChart
					data={stats.historyBalance}
					margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
					<defs>
						<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="var(--link-color)" stopOpacity={0.4} />
							<stop offset="110%" stopColor="var(--link-color)" stopOpacity={0} />
						</linearGradient>
					</defs>
					<Tooltip content={CustomTooltip} />
					<Area
						type="monotone"
						dataKey="value"
						stroke="var(--link-color)"
						fillOpacity={1}
						fill="url(#colorPv)"
					/>
					<XAxis dataKey="date" hide={true} />
				</AreaChart>
			</ResponsiveContainer>
			<div className={styles.chart_buttons}>
				{filters.map((el) => (
					<button
						key={el}
						onClick={() => setFilter(el)}
						className={cn(styles.chart_btn, { [styles.active]: el === filter })}>
						{el}d
					</button>
				))}
			</div>
		</div>
	);
};

export default Chart;

const CustomTooltip: ContentType<ValueType, NameType> = ({ active, payload, label }) => {
	useEffect(() => {
		if (active) {
			window.ontouchmove = (e) => {
				e.stopPropagation();
				e.preventDefault();
			};
		} else {
			window.ontouchmove = null;
		}
	}, [active]);

	if (active) {
		const value =
			payload !== undefined &&
			payload.length > 0 &&
			payload[0].value !== undefined &&
			typeof payload[0].value === 'number'
				? payload[0].value
				: undefined;

		return (
			<div className={styles.tooltip}>
				<h4 className={styles.tooltip_label}>{label}</h4>
				{value !== undefined && (
					<p className={styles.tooltip_value}>{value.toFixed(2)} USDT</p>
				)}
			</div>
		);
	}
	return null;
};
