import { FC, useEffect } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { deleteStrategy, getStrategies } from 'store/reducers/strategySlice';

import deleteImg from 'assets/img/delete.svg';
import styles from './Strategies.module.scss';

interface IStrategies {
	isAdminList?: boolean;
}

const Strategies: FC<IStrategies> = ({ isAdminList = false }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { strategies } = useAppSelector((state) => state.strategy);

	useEffect(() => {
		dispatch(getStrategies());
	}, [dispatch]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Available strategies</div>
			<div className={styles.list}>
				{strategies.map((s) => (
					<div
						key={s.id}
						onClick={!isAdminList ? () => navigate(`/strategy/${s.id}`) : undefined}
						className={cn(styles.item, { [styles.hover]: !isAdminList })}>
						<p className={styles.name}>
							<div className={styles.image}>
								{s.image && (
									<img
										src={process.env.REACT_APP_API_URL + `/uploads/${s.image}`}
										onError={(e) => {
											e.currentTarget.style.display = 'none';
										}}
										alt="Strategy icon"
									/>
								)}
							</div>
							{s.name}
						</p>
						<p className={styles.percent}>+{((s.rate - 1) * 100).toFixed(2)}%</p>
						<p className={styles.days}>
							Placement period:
							<p>{s.days} days</p>
						</p>
						<p className={styles.deposit}>
							<p>Deposit (USDT)</p>
							<p>
								{s.minDeposit} - {s.maxDeposit}
							</p>
						</p>
						{isAdminList && (
							<button
								onClick={() => dispatch(deleteStrategy(s.id))}
								className={styles.delete}>
								<img src={deleteImg} alt="delete" />
							</button>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default Strategies;
