import { FC } from 'react';

import styles from './Status.module.scss';
import cn from 'classnames';

type StatusType = 'beginner' | 'bronze' | 'silver' | 'gold' | 'platinum';

interface IStatus {
	status: StatusType;
	days: number;
	maxDays: number;
}

const percents: Record<StatusType, number> = {
	beginner: 0,
	bronze: 2,
	silver: 4,
	gold: 6,
	platinum: 8,
};

const Status: FC<IStatus> = ({ status, days, maxDays }) => {
	return (
		<div className={cn(styles.wrapper, styles[status])}>
			<div className={styles.title}>
				<div>{status[0].toUpperCase() + status.slice(1)}</div>
				<div className={styles.percent}>+{percents[status]}%</div>
			</div>
			<div
				style={{
					width: '100%',
					height: 6,
					borderRadius: 100,
					background: 'rgba(239, 239, 244, 0.6)',
					position: 'relative',
				}}>
				<div
					style={{
						position: 'absolute',
						inset: 0,
						right: `${((1 - days / maxDays) * 100).toFixed()}%`,
						borderRadius: 100,
						background: 'rgba(0, 0, 0, 0.6)',
					}}
				/>
			</div>
			<div className={styles.days}>
				{days}/{maxDays} days
			</div>
		</div>
	);
};

export default Status;
