import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkWallet } from 'store/reducers/userSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { Info, Button, Strategies } from 'components';
import {
	AddIcon,
	RefereesIcon,
	SendIcon,
	TransactionsIcon,
	WalletCheckIcon,
} from 'icons';

import styles from './Main.module.scss';
import cn from 'classnames';
import { getHoldings, getHoldingsHistory } from 'store/reducers/holdingSlice';
import Status from 'components/Status/Status';
import Chart from 'components/Chart/Chart';

const Main: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { history, holdings } = useAppSelector((state) => state.holding);

	useEffect(() => {
		dispatch(getHoldingsHistory());
		dispatch(getHoldings());

		const interval = setInterval(() => {
			dispatch(checkWallet());
		}, 30 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [dispatch]);

	const { user, wallet } = useAppSelector((state) => state.user);

	console.log(holdings);

	return (
		<div className={styles.wrapper}>
			<button
				className={styles.transactions_btn}
				onClick={() => navigate('/transactions')}>
				<TransactionsIcon />
			</button>
			<div className={styles.content}>
				<div className={styles.header}>
					<div className={styles.balance}>
						<p>Free Balance</p>
						<div className={styles.balance_value}>
							<div className={styles.sum}>
								<span>$</span> {wallet.availableSum.toFixed(2)}
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', gap: 12 }}>
						<Button onClick={() => navigate('/payment')} className={styles.button}>
							<AddIcon />
							Add Crypto
						</Button>
						<Button onClick={() => navigate('/send')} className={styles.button}>
							<SendIcon />
							Send
						</Button>
					</div>
					{(holdings.length > 0 || history.length > 0) && (
						<>
							<div className={styles.status}>
								<Status days={1} maxDays={12} status="beginner" />
							</div>
							<Chart />
						</>
					)}
					<div className={styles.buttons}>
						<Button
							color="white"
							onClick={() => navigate('/holdings')}
							className={cn(styles.button, styles.fw)}>
							<WalletCheckIcon />
							My Strategies
						</Button>
						<Button
							color="white"
							onClick={() => navigate('/referees')}
							className={cn(styles.button, styles.fw)}>
							<RefereesIcon />
							My Referees
						</Button>
					</div>
				</div>
				<Info />
				<Strategies />
				{user.role === 'MAIN' && (
					<button onClick={() => navigate('/admin')} className={styles.admin_button}>
						Админка
					</button>
				)}
			</div>
		</div>
	);
};

export default Main;
